<template>
  <!-- 
  1-自定义填写未完成
  2-问卷权限设置未完成
  -->
  <div class="tc-evaluate-adduser">
    <!-- 头部工具栏 -->
    <div class="tool">
      <ul>
        <li>
          <router-link to="/user_evaluate/bulkImport">
            <img src="../../../../assets/images/batchAddbtn.png" alt height="40.69" />
          </router-link>
        </li>
        <li>
          <img src="../../../../assets/images/refreshBtn.png" @click="refresh()" alt />
        </li>
        <li>
          <img src="../../../../assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <el-col class="adduserform">
      <el-form ref="form" :model="form" label-width="80px" size="mini" :rules="rules">
        <el-row class="left">
          <!-- 用户信息创建 -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="增加单个用户" name="first" class="single">
              <!-- 第一列 -->
              <el-col :span="5">
                <el-form-item label="用户编号" prop="account">
                  <el-input v-model="form.account" maxlength="14"></el-input>
                </el-form-item>
                <el-form-item label="用户姓名" prop="real_name">
                  <el-input v-model="form.real_name" maxlength="12"></el-input>
                </el-form-item>
              </el-col>
              <!-- 第二列 -->
              <el-col :span="5">
                <el-form-item label="密码" prop="password">
                  <el-input type="password" v-model="form.password" maxlength="16"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="affpassword">
                  <el-input type="password" v-model="form.affpassword" maxlength="16"></el-input>
                </el-form-item>
              </el-col>
              <!-- 3 -->
              <el-col :span="5">
                <!-- <el-form-item label="出生日期" prop="age">
                  <el-date-picker
                    :picker-options="pickerOptions0"
                    value-format="yyyy-MM-dd"
                    v-model="form.age"
                    type="date"
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item> -->
                <el-form-item label="年龄" prop="ages">
                  <el-input type="number" v-model="form.ages" maxlength="16" :min="0"></el-input>
                </el-form-item>

                <el-form-item label="性别" prop="sex">
                  <el-select v-model="form.sex">
                    <el-option label="男" :value="1"></el-option>
                    <el-option label="女" :value="0"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 4 -->
              <el-col :span="5">
                <el-form-item label="部门名称" prop="department_id">
                  <el-select v-model="form.department_id">
                    <el-option v-for="item in departmentList" :key="item.id" :label="item.name" :value="item.id">{{
                      item.name
                    }}</el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="文化程度" prop="culture">
                  <el-select v-model="form.culture">
                    <el-option label="文盲" value="文盲"></el-option>
                    <el-option label="小学" value="小学"></el-option>
                    <el-option label="初中" value="初中"></el-option>
                    <el-option label="高中/中专/技校" value="高中/中专/技校"></el-option>
                    <el-option label="大学专科" value="大学专科"></el-option>
                    <el-option label="大学本科" value="大学本科"></el-option>
                    <el-option label="硕士" value="硕士"></el-option>
                    <el-option label="博士及以上" value="博士及以上"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="5">
                <el-form-item label="身份证号" prop="idcard">
                  <el-input v-model="form.idcard" maxlength="18"></el-input>
                </el-form-item>
              </el-col> -->
            </el-tab-pane>
            <el-tab-pane label="同时增加多个用户" name="second" class="multi">
              <div class="serial">
                <div class="title">
                  <p>序列号范围</p>
                </div>
                <div class="serial-from">
                  <el-form-item label="最小值 " label-width="90px">
                    <el-input v-model="form.min"></el-input>
                  </el-form-item>
                  <el-form-item label="最大值 ">
                    <el-input v-model="form.max"></el-input>
                  </el-form-item>
                </div>
                <div class="ps">
                  <p>生成用户个数为(最大序列数－最小序列数+1)</p>
                </div>
              </div>
              <div class="serial-repair">
                <div class="title">
                  <p>序列数补零</p>
                </div>
                <div class="serial-repair-from">
                  <el-form-item label="序列数不够" label-width="90px" prop="ran_len">
                    <div class="num">
                      <el-input v-model="form.ran_len" onkeyup="value=value.replace(/[^\d]/g,'')">1</el-input>
                      <span>位</span>
                    </div>
                  </el-form-item>
                  <el-form-item label="序列数前面补零" label-width="120px">
                    <el-radio-group v-model="form.resource">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
              <div class="number">
                <div class="title">
                  <p>用户编号生成规则</p>
                </div>
                <div class="number-from">
                  <div class="number-from-1">
                    <div class="radio">
                      <el-form-item>
                        <el-radio-group v-model="form.a_radio">
                          <el-radio label="a_pre">前缀固定，用户编号为前缀+序列数</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div class="input">
                      <el-form-item label="前缀名" label-width="80px">
                        <el-input v-model="form.a_pre_len" placeholder="不填为空"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="number-from-1">
                    <div class="radio">
                      <el-form-item>
                        <el-radio-group v-model="form.a_radio">
                          <el-radio label="a_last">后缀固定，用户编号为序列数+后缀</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div class="input">
                      <el-form-item label="后缀名" label-width="80px">
                        <el-input v-model="form.a_last_len" placeholder="不填为空"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pass">
                <div class="title">
                  <p>用户密码规则</p>
                </div>
                <div class="pass-from">
                  <div class="pass-from-1">
                    <div class="radio">
                      <el-form-item>
                        <el-radio-group v-model="form.identical">
                          <el-radio :label="1">和用户编号相同</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <!-- <div class="input">
                      <el-form-item label="前缀名" label-width="80px">
                        <el-input v-model="form.name" placeholder="不填为空"></el-input>
                      </el-form-item>
                    </div>-->
                  </div>
                  <div class="pass-from-1">
                    <div class="radio">
                      <el-form-item>
                        <el-radio-group v-model="form.identical">
                          <el-radio :label="0">统一固定密码</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div class="input">
                      <el-form-item>
                        <el-input v-model="form.batchPassword" placeholder="默认密码：123456"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
              <el-form-item label="部门名称" prop="group_department_id">
                <el-select v-model="form.group_department_id">
                  <el-option v-for="item in departmentList" :key="item.id" :label="item.name" :value="item.id">{{
                    item.name
                  }}</el-option>
                </el-select>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-row>
        <!-- 量表权限 -->
        <el-row class="right">
          <el-tabs v-model="scale" @tab-click="handleClick">
            <el-tab-pane label="所有可用量表" name="first">
              <div class="query">
                <p>量表权限设置</p>
                <el-form :inline="true" :model="form" size="mini">
                  <el-form-item>
                    <el-input v-model="form.scaleName" placeholder="量表名称" @keyup.enter.native="queryScale()"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="queryScale()">量表查询</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="checkAll(1)">全选或取消所有量表</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="checkAll(2)">全选或取消测评人查看结果</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <!-- 所有量表 -->
              <div class="table" @change="handleCheckAllChange">
                <table>
                  <tr>
                    <th>量表名称</th>
                    <th>使用该量表</th>
                    <th>允许测评人看测评结果</th>
                    <th>测评次数</th>
                  </tr>
                  <!-- ----------------------- -->
                  <template v-for="(item, key) in scaleData">
                    <tr class="title" :key="key" v-if="item.status_measure.length > 0">
                      <td colspan="4">{{ item.classify_name }}</td>
                    </tr>
                    <tr v-for="item2 in item.status_measure" :key="item2.classify_name">
                      <td>{{ item2.measure_title }}</td>
                      <td>
                        <el-checkbox-group v-model="checkList">
                          <el-checkbox :label="item2.id" name="a"></el-checkbox>
                        </el-checkbox-group>
                      </td>
                      <td>
                        <el-checkbox-group v-model="checkList2">
                          <el-checkbox :label="item2.id" name="b"></el-checkbox>
                        </el-checkbox-group>
                      </td>
                      <td>
                        <el-input v-model="item2.max_num" placeholder="" size="mini" :name="item2.id" maxlength="2"
                          onkeyup="value=value.replace(/[^\d]/g,'')"></el-input>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
              <el-col :span="5">
                <el-form-item label="已测量表是否显示" label-width="150px">
                  <el-radio-group v-model="form.show_finish_measure">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-tab-pane>
            <el-tab-pane label="量表组合包" name="second">
              <div class="query">
                <p>量表分组设置</p>
              </div>
              <div class="table">
                <table @change="handleCheckAllChange2">
                  <tr>
                    <th>分组量表名称</th>
                    <th>量表数量</th>
                    <!-- <th>次数限制</th> -->
                    <th>应用该分组</th>
                  </tr>
                  <!-- ----------------------- -->
                  <tr v-for="(item, key) in scaleData2.data" :key="key">
                    <td>{{ item.group_name }}</td>
                    <td>{{ item.measure_count }}</td>
                    <!-- <td>{{ item.test_num }}</td> -->
                    <td>
                      <el-checkbox-group v-model="checkList3">
                        <el-checkbox :label="item.id"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                  </tr>
                </table>
              </div>
            </el-tab-pane>
          </el-tabs>
          <el-col :span="8">
            <!-- <el-form-item
              label="每份量表测评次数"
              label-width="150px"
              v-if="true"
            >
              <el-input
                v-model="form.measurement_num"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                maxlength="3"
              ></el-input>
            </el-form-item> -->

            <el-form-item label="测评时间范围" label-width="150px" prop="date">
              <!-- <el-date-picker
                v-model="form.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              ></el-date-picker> -->

              <!-- <div class="twoData"> -->
              <el-date-picker v-model="form.date[0]" type="date" placeholder="选择开始日期" value-format="timestamp"
                style="width:180px">
              </el-date-picker>
              至
              <!-- 结束 -->
              <el-date-picker v-model="form.date[1]" type="date" placeholder="选择结束日期" value-format="timestamp"
                style="width:180px">
              </el-date-picker>
              <!-- </div> -->
            </el-form-item>
            <span style="color: #606266">注意：可不填写日期，默认为：不限制</span>

          </el-col>
        </el-row>
        <!-- 问卷权限 -->
        <el-col class="msqtable">
          <p class="extend" @click="msqHide()">
            <span v-if="msq">关闭</span>
            <span v-if="!msq">打开</span>问卷权限设置
          </p>
          <el-collapse-transition>
            <div class="table" @change="handleCheckAllChange3" v-if="msq">
              <table>
                <tr>
                  <th>问卷(试卷)名称</th>
                  <th>使用该问卷</th>
                  <!-- <th>允许测评人看到得分</th> -->
                </tr>
                <!-- ----------------------- -->
                <tr class="title">
                  <td colspan="3">选择要授权的问卷</td>
                </tr>
                <!--  v-if="item[0].status_questionnaires !=0" -->
                <template v-for="item in questionnaireData">
                  <tr class="title" :key="item.id" v-if="item[0].status_questionnaires.length != 0">
                    <td colspan="3">{{ item[0].type_name }}</td>
                  </tr>
                  <tr v-for="item2 in item[0].status_questionnaires" :key="item2.id">
                    <td>{{ item2.title }}</td>
                    <td>
                      <el-checkbox-group v-model="checkList11">
                        <el-checkbox :label="item2.id" name="a"></el-checkbox>
                      </el-checkbox-group>
                    </td>
                    <!-- <td>
                      <el-checkbox-group v-model="checkList22">
                        <el-checkbox :label="item2.id" name="b"></el-checkbox>
                      </el-checkbox-group>
                    </td> -->
                  </tr>
                </template>
              </table>
            </div>
          </el-collapse-transition>
        </el-col>
        <!--选填内容 -->
        <el-col>
          <p class="extend" v-if="activeName === 'first'" @click="openOptional()">
            <span v-if="optionalHide">关闭</span>
            <span v-if="!optionalHide">打开</span>选填信息（可为空）
          </p>
          <el-collapse-transition>
            <div class="optional" v-if="optionalHide && activeName === 'first'">
              <el-row>
                <el-col>
                  <p>头像</p>
                  <el-upload class="avatar-uploader" action="/file_upload" :show-file-list="false"
                    :on-success="fileUploadApi" :before-upload="beforeAvatarUpload" :data="fileData">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-col>
              </el-row>
              <!-- 自填信息 -->
              <el-col :span="5">
                <template v-for="item in archivesData">
                  <!-- 单行文本 -->
                  <el-col :key="item.id" v-if="item.type === 1">
                    <el-form-item :label="item.name">
                      <el-input v-model="form.archives[item.key]"></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- 下拉框 -->
                  <el-col :key="item.id" v-if="item.type === 3">
                    <el-form-item :label="item.name">
                      <el-select v-model="form.archives[item.key]">
                        <el-option :label="item2" :value="item2" v-for="(item2, key) in item.content"
                          :key="key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- 单选 -->
                  <el-col :key="item.id" v-if="item.type === 4">
                    <el-form-item :label="item.name">
                      <el-radio-group v-model="form.archives[item.key]">
                        <el-radio :label="item2" v-for="(item2, key) in item.content" :key="key"></el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <!-- 复选 -->
                  <el-col :key="item.id" v-if="item.type === 5">
                    <el-form-item :label="item.name">
                      <el-checkbox-group v-model="form.archives[item.key]">
                        <el-checkbox :label="item2" v-for="(item2, key) in item.content" :key="key"></el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                  <!-- 文本域 -->
                  <el-col :key="item.id" v-if="item.type === 2">
                    <el-form-item :label="item.name">
                      <el-input type="textarea" v-model="form.archives[item.key]" :rows="5"></el-input>
                    </el-form-item>
                  </el-col>
                </template>
              </el-col>
            </div>
          </el-collapse-transition>
        </el-col>
        <!-- 提交按钮 -->
        <div class="addUserBtn" @click="addUserinfo()">
          <img src="../../../../assets/images/addbtn.png" alt />
        </div>
      </el-form>
    </el-col>
  </div>
</template>

<script>
import axios from "axios";
import { log } from "util";
import {
  addUser,
  addUserAll,
  loadArchivesInfo,
  getAddIndex,
} from "@/api/evaluate.js";
import { getAllQuestionnaires, getbywhere } from "@/api/comm.js";
export default {
  inject: ["reload"],
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.affpassword !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        this.ispassword = false;
        callback(new Error("两次输入密码不一致!"));
      } else {
        this.ispassword = true;
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      }
    };
    var validateAcc = (rule, value, callback) => {
      if (rule) {
        getbywhere({ account: this.form.account }).then((res) => {
          if (res.msg) {
            callback(new Error("对不起，用户账户已存在"));
          } else if (rule) {
            let rgx = /^[0-9a-zA-Z_]{1,}$/;
            if (value !== "") {
              if (!rgx.test(value)) {
                callback(new Error("只能输入，字母数字下划线"));
              } else {
                callback();
              }
            }
          } else {
            callback();
          }
        });
      } else {
        callback();
      }
    };
    var validateAccount = (rule, value, callback) => {
      if (value) {
        let rgx = /^[0-9a-zA-Z_]{1,}$/;
        if (value !== "") {
          if (!rgx.test(value)) {
            callback(new Error("只能输入，字母数字下划线"));
          } else {
            callback();
          }
        }
      }
    };
    var validateIdc = (rule, value, callback) => {
      let reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
      if (value === "") {
        callback(new Error("身份证不能为空"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的身份证号码"));
      } else {
        callback();
      }
    };
    return {
      // 验证
      rules: {
        password: [
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        affpassword: [
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur" },
        ],
        account: [
          {
            required: true,
            min: 4,
            max: 14,
            message: "长度在 4 到 14 个字符",
            trigger: "blur",
          },
          { validator: validateAcc, trigger: ["blur", "change"] },
        ],
        idcard: [
          {
            required: true,
            min: 18,
            max: 18,
            message: "身份证号长度不符",
            trigger: "blur",
          },
          { validator: validateIdc, trigger: ["blur", "change"] },
        ],
        real_name: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          {
            min: 2,
            max: 16,
            message: "长度在 2 到 16 个字符",
            trigger: "blur",
          },
        ],
        // age: [{ required: true, message: "请选择出生日期", trigger: "blur" }],
        ages: [{ required: true, message: "请输入年龄", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        department_id: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        culture: [
          { required: true, message: "请选择文化程度", trigger: "change" },
        ],
        // date: [
        //   { required: true, message: "请选择测评时间范围", trigger: "blur" }
        // ],
        group_department_id: [
          { required: true, message: "请选择部门", trigger: "change" },
        ],
        ran_len: [{ required: true, message: "请填写位数", trigger: "change" }],
      },
      // 表单
      form: {
        // ----------单--------------
        account: "",
        real_name: "",
        password: "",
        affpassword: "",
        sex: "",
        age: "",
        idcard: "",
        ages: "",
        culture: "",
        department_id: "",
        //----------- 多---------------
        min: 1,
        max: 5,
        ran_len: "",
        a_radio: "a_pre",
        a_pre_len: "",
        a_last_len: "",
        identical: 1,
        department_id: "",
        group_department_id: "",
        begin_time: 0,
        end_time: 0,
        batchPassword: "",
        scaleName: "",
        pingyin: "",
        resource: 0,
        // 都有
        measure_group: "", //组合量表包
        // measurement_num: 1,
        measure: [],
        show_finish_measure: 1,
        begin_time: "",
        end_time: "",
        // 调查问卷
        questionnaire: [],
        // 自定义填写内容
        archives: {},
        head_id: "",
        date: [],
      },
      // 用户tab栏
      activeName: "first",
      // 量表tab栏
      scale: "first",
      // 所有量表-是否使用
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      // 所有量表数据渲染
      scaleData: [],
      // 所有量表数据存储
      scaleData1: [],
      // 量表组合包-是否使用
      checkList3: [],
      //量表组合包数据
      scaleData2: [],
      // 所有量表-全选控制
      checkAll1: 1,
      checkAll2: 1,
      // 部门数据
      departmentList: [],
      imageUrl: "",
      optionalHide: false,
      msq: false,
      fileData: {
        type: 0,
        attribution_id: 1, // 当前 用户的id
      },
      // 调查问卷
      checkList11: [],
      checkList22: [],
      scaleData11: [],
      questionnaireData: [],
      // 自定义选择信息
      archivesData: [],
      ispassword: true,
      // 问卷数据
      quesData: [],
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //只能选择今天及今天之前
        },
      },
    };
  },
  created() {
    // 问卷加载
    this.loadQuestionnaires();
    // 量表加载
    this.loadScaleData();
    this.loadScaleData2();
    // 获取部门
    this.departmentList = JSON.parse(localStorage.getItem("departmentList"));
    // 加载自定义档案信息
    // this.loadArchives();
    // 获取用户信息
    getAddIndex().then((res) => {
      //console.log(res);
      if (res.code == 400200) {
        // 分离数据
        let { user, archives } = res.data;
        this.archivesData = archives;
        // 加载自定义档案
        this.archivesData.forEach((v) => {
          let data = {};
          if (v.type === 3 || v.type === 5) {
            this.$set(this.form.archives, v.key, []);
          } else {
            this.$set(this.form.archives, v.key, "");
          }
        });
        // 填充用户名和密码
        if (user != "") {
          this.form.account = user.account + "";
          this.form.password = user.password;
          this.form.affpassword = user.password;
        }
      }
    });
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    handleClick(tab, event) {
      // //console.log(tab);
    },
    //判断是否选择了量表，是否有权利选择结果,处理数据到  form.measure 中
    handleCheckAllChange(val) {
      var a = parseInt(val.target.value);
      if (val.target.name === "a") {
        var obj = { measure_id: a, show_result: "" || 0 };
        var tmp = this.form.measure.some(function (item) {
          return item.measure_id === a;
        });
        if (tmp) {
          this.form.measure = this.form.measure.filter((v) => {
            return v.measure_id != val.target.value;
          });
        } else {
          this.form.measure.push(obj);
        }
      } else if (val.target.name === "b") {
        var p = this.checkList.indexOf(a);
        var index = this.form.measure.findIndex((item) => {
          return item.measure_id === a;
        });
        if (this.form.measure[index] && index != -1) {
          if (this.form.measure[index].show_result === 0) {
            this.form.measure[index].show_result = 1;
          } else {
            this.form.measure[index].show_result = 0;
          }
        }

        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList2 = this.checkList2.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      } else {
        let st = true;
        this.form.measure.forEach((v) => {
          if (v.measure_id == val.target.name) {
            v.max_num = val.target.value || 0;
            st = false;
          }
        });
        if (st) {
          this.$message({
            message: "警告,量表未勾选",
            type: "warning",
          });
          val.target.value = "";
        }
      }
    },
    // 全选、取消
    checkAll(res) {
      // 量表
      if (res === 1) {
        if (this.checkAll1 === 1) {
          this.scaleData.forEach((v) => {
            v.status_measure.forEach((v) => {
              this.checkList.push(v.id);
              var obj = { measure_id: v.id, show_result: 0 };
              this.form.measure.push(obj);
            });
          });
          this.checkAll1 = 0;
        } else {
          this.checkList = [];
          this.checkList2 = [];
          this.form.measure = [];
          this.checkAll1 = 1;
        }
      } else {
        if (this.checkAll2 === 1) {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //2-把  checkList 的值 给 checkList2
          let a = this.checkList;
          this.checkList2 = a;
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 1;
              }
            }
          });
          this.checkAll2 = 0;
        } else {
          //1-先判断是否有选择的量表
          if (this.checkList <= 0) {
            this.$message.error("请选择量表后，再全选");
            return;
          }
          //3-通过  checkList2 的值 依次 寻找measure 中的 show_result 改成 1
          this.checkList2.forEach((v, k) => {
            for (var i = 0; i < this.form.measure.length; i++) {
              if (this.form.measure[i].measure_id === v) {
                this.form.measure[i].show_result = 0;
              }
            }
          });
          this.checkList2 = [];
          this.checkAll2 = 1;
        }
      }
    },
    // 组合量表
    handleCheckAllChange2(val) {
      // 清除所有;
      this.checkList3 = [];
      this.checkList3.push(parseInt(val.target.value));
    },
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        this.scaleData1 = res.data.data;
      } else {
        return;
      }
    },
    //组合量表包请求
    async loadScaleData2() {
      var res = await axios.get("/list_measure_group?page=1&size=999");
      this.scaleData2 = res.data.data;
      //console.log("组合", this.scaleData2);
    },
    // 添加按钮
    addUserinfo() {
      // 1-数据处理
      // 时间:
      let begin_time = 0;
      let end_time = 0;
      if (this.form.date[0] || this.form.date[1]) {
        if (!this.form.date[0] || !this.form.date[1]) {
          this.$message({
            message: "请选择完整评测时间范围或者不选择评测时间范围!",
            type: "error",
          });
          return
        }
      }
      if (this.form.date.length != 0) {
        if (this.form.date[0] > this.form.date[1]) {
          this.$message({
            message: "评测时间范围结束时间请大于开始时间!",
            type: "error",
          });
          return
        }
        begin_time = parseInt(this.form.date[0] / 1000);
        end_time = parseInt(this.form.date[1] / 1000);
      }

      // 表单处理
      if (this.activeName === "first") {
        // //console.log("我是单用户添加");
        this.form.age = this.form.ages;
        let {
          account,
          idcard,
          password,
          affpassword,
          sex,
          age,
          ages,
          culture,
          department_id,
          real_name,
          archives,
          head_id,
          questionnaire,
        } = this.form;

        let obj = {
          account,
          password,
          idcard,
          affpassword,
          sex,
          age,
          idcard,
          ages,
          culture,
          department_id,
          begin_time,
          end_time,
          real_name,
          archives,
          head_id: head_id || 0,
          questionnaire,
        };
        // if (!obj.idcard) {
        //   this.$message({
        //     message: "身份证号码不能为空!",
        //     type: "error",
        //   });
        //   return;
        // } else {
        //   let reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
        //   if (!reg.test(obj.idcard)) {
        //     this.$message({
        //       message: "请输入正确的身份证号码!",
        //       type: "error",
        //     });
        //     return;
        //   }
        // }

        this.$refs["form"].validate((valid) => {
          // //console.log(valid);
          if (!valid) {
            return false;
          }
        });
        if (!this.ispassword) {
          this.$message.error("两次密码不一致");
          return;
        }
        if (this.scale === "first") {
          // 所有量表
          var { show_finish_measure, measure } = this.form;
          obj.show_finish_measure = show_finish_measure;
          obj.measure = measure;
        } else {
          // 量表组
          obj.measure_group = this.checkList3[0];
        }
        // //console.log(obj);
        //  发送请求
        addUser(obj).then((res) => {
          // //console.log(res);
          if (res.code === 400200) {
            this.$message({
              message: "恭喜你，添加用户成功",
              type: "success",
            });
            this.back();
          }
        });
      } else {
        //  多用户添加
        let arr = ["group_department_id", "date"];
        if (this.form.resource == 1) {
          arr.push("ran_len");
        }
        this.$refs["form"].validateField(arr, (valid) => {
          // //console.log(valid);
          if (!valid) {
            return false;
          }
        });
        let {
          min,
          max,
          ran_len,
          a_radio,
          a_pre_len,
          a_last_len,
          identical,
          batchPassword,
          department_id,
          group_department_id,
          questionnaire,
        } = this.form;
        let obj = {
          min,
          max,
          ran_len,
          a_radio,
          a_pre_len,
          a_last_len,
          identical,
          batch_password: batchPassword,
          department_id: group_department_id,
          begin_time,
          end_time,
          questionnaire,
        };
        if (this.form.identical === 0 && this.form.batchPassword == "") {
          obj.batch_password = "123456";
        }
        if (this.scale === "first") {
          // 所有量表
          var { show_finish_measure, measure } = this.form;

          obj.show_finish_measure = show_finish_measure;
          obj.measure = measure;
        } else {
          // 量表组
          obj.measure_group = this.checkList3[0];
        }
        //  发送请求
        // //console.log(obj);
        addUserAll(obj).then((res) => {
          if (res.code === 400200) {
            this.$message({
              message: "恭喜你，添加用户成功",
              type: "success",
            });
            this.back();
          }
        });
      }
    },
    // 文件上传成功的狗子
    fileUploadApi(response, file) {
      this.form.head_id = response.data.id;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 图片上次校验
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      const isLt50M = file.size / 1024 / 1024 < 2;
      if (!extension) {
        this.$message({
          message: "上传图片只能是jpg / png / bpm格式!",
          type: "error",
        });
        return false;
      }
      //console.log(file);
      if (!isLt50M) {
        this.$message({
          message: "上传文件大小不能超过 2MB!",
          type: "error",
        });
        return false;
      }
      return extension || isLt50M;
    },
    // 打开关闭问卷权限
    msqHide() {
      if (!this.msq) {
        this.msq = true;
      } else {
        this.msq = false;
      }
    },
    // 打开关闭自定义填写框
    openOptional() {
      if (!this.optionalHide) {
        this.optionalHide = true;
      } else {
        this.optionalHide = false;
      }
    },
    // 量表查询
    queryScale() {
      var str = this.form.scaleName;
      if (str === "") {
        this.scaleData = this.scaleData1;
        return;
      }
      var obj = [{ classify_name: "查询结果", id: 1, status_measure: [] }];
      for (let k in this.scaleData1) {
        if (this.scaleData1[k].id) {
          this.scaleData1[k].status_measure.forEach((v) => {
            if (v.measure_title.indexOf(str) != -1) {
              obj[0].status_measure.push(v);
            }
          });
        }
      }
      this.scaleData = obj;
    },
    // 调查问卷 勾选、数据处理
    handleCheckAllChange3(val) {
      var a = parseInt(val.target.value);
      if (val.target.name == "a") {
        var obj = { questionnaire_id: a, show_result: "" || 0 };
        var tmp = this.form.questionnaire.some(function (item) {
          return item.questionnaire_id === a;
        });
        if (tmp) {
          this.form.questionnaire = this.form.questionnaire.filter((v) => {
            return v.questionnaire_id != val.target.value;
          });
        } else {
          this.form.questionnaire.push(obj);
        }
      }
      if (val.target.name == "b") {
        var p = this.checkList11.indexOf(a);
        var index = this.form.questionnaire.findIndex((item) => {
          return item.questionnaire_id === a;
        });
        if (this.form.questionnaire[index] && index != -1) {
          if (this.form.questionnaire[index].show_result === 0) {
            this.form.questionnaire[index].show_result = 1;
          } else {
            this.form.questionnaire[index].show_result = 0;
          }
        }
        if (p === -1) {
          this.$message.error("请先选择该表单后，在选择此选项");
          this.checkList22 = this.checkList22.filter((v) => {
            return v != val.target.value;
          });
          return;
        }
      }
    },
    // 加载自定义档案信息
    loadArchives() {
      loadArchivesInfo().then((res) => {
        this.archivesData = res.data;
        this.archivesData.forEach((v) => {
          let data = {};
          if (v.type === 3 || v.type === 5) {
            this.$set(this.form.archives, v.key, []);
          } else {
            this.$set(this.form.archives, v.key, "");
          }
        });
      });
    },
    // 问卷数据
    loadQuestionnaires() {
      getAllQuestionnaires().then((res) => {
        this.questionnaireData = res.data;
        console.log(res.data);
      });
    },
  },
  watch: {},
};
</script>

<style lang="less">
.tc-evaluate-adduser {
  width: 100%;
  min-width: 1300px;
  position: relative;

  .tool {
    position: absolute;
    top: -50px;
    right: 0;

    ul {
      li {
        cursor: pointer;
        list-style: none;
        float: left;
        margin-left: 10px;

        img {
          width: 100px;
        }
      }
    }
  }

  .adduserform {
    width: 100%;

    .left {
      .single {
        .el-input__inner {
          width: 200px;
        }
      }

      .multi {
        .el-input__inner {
          width: 100px;
        }

        // 用户信息
        .serial {
          display: flex;

          .serial-from {
            display: flex;
          }
        }

        .serial-repair {
          display: flex;

          .serial-repair-from {
            display: flex;

            .num {
              display: flex;

              span {
                line-height: 28px;
                font-size: 14px;
                color: #606266;
                margin-bottom: 10px;
                padding-left: 10px;
              }
            }
          }
        }

        .number {
          display: flex;

          .number-from {
            display: flex;
            flex-direction: column;

            .number-from-1 {
              display: flex;
            }

            .radio {
              .el-form-item__content {
                margin-left: 20px !important;
              }
            }

            .input {}
          }
        }

        .pass {
          display: flex;

          .pass-from {
            display: flex;
            flex-direction: column;

            .pass-from-1 {
              display: flex;
            }

            .radio {
              .el-form-item__content {
                margin-left: 20px !important;
              }
            }

            .input {
              .el-form-item__content {
                margin-left: 10px !important;
              }

              .el-input__inner {
                width: 150px;
                padding-right: 20px;
              }
            }
          }
        }

        p {
          line-height: 28px;
          font-size: 14px;
          color: #606266;
          margin-bottom: 10px;
          padding-left: 10px;
        }

        .title {
          width: 130px;
        }

        .ps {
          width: 300px;
        }
      }
    }

    .right {}
  }

  // tab内边距
  .el-tab-pane {
    // padding-left: 40px;
  }

  .table {
    width: 950px;
    height: 300px;
    overflow: auto;
    border: 1px #cccccc solid;
    margin-bottom: 20px;

    .el-input__inner {
      width: 120px;
      padding-right: 20px;
    }

    table {
      width: 945px;
      text-align: center;
      overflow: auto;
      border-collapse: collapse;

      th {
        border-bottom: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
      }

      tr,
      th {
        height: 20px;
        padding: 0;
        font-weight: 300;
        font-size: 14px;
      }

      tr {
        td {
          padding: 2px 5px;

          &:nth-child(1) {
            text-align: left;
          }

          .el-input-number {
            .el-input__inner {
              padding: 0;
            }
          }
        }

        &:hover {
          background: #b4e7e9;
        }
      }

      th {
        height: 30px;
        font-size: 15px;
        background: rgb(224, 245, 250);
      }

      .title {
        background: rgb(224, 245, 250);
        text-align: left;
        font-weight: 700;
        font-size: 15px;
      }

      .el-checkbox__label {
        display: none;
      }
    }
  }

  .query {
    p {
      line-height: 30px;
      font-size: 14px;
      color: #606266;
      margin-bottom: 10px;
      padding-left: 10px;
    }
  }

  .extend {
    color: rgb(12, 118, 172);
    margin: 5px;
    cursor: pointer;
    text-decoration: underline;
  }

  .addUserBtn {
    margin-top: 10px;
    text-align: center;

    // .el-form-item__content {
    //   margin-left: 0 !important;
    // }
    img {
      width: 100px;
    }
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 16px;
  }

  .optional {
    padding-bottom: 10px;

    // 头像
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }

    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }

    .avatar-uploader {
      // display: flex;
      // justify-content: center;
      padding-left: 110px;
      padding-bottom: 10px;
    }

    p {
      padding-left: 148px;
      padding-bottom: 5px;
      font-size: 14px;
      color: #606266;
    }

    .el-col {
      padding-right: 1 0px;
    }
  }

  .el-form-item__label:before {
    display: none;
  }
}
</style>